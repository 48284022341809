// Vendor
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// neetoUI
@import "@bigbinary/neetoui";

@import "abstracts/neeto-ui-variables";
@import "abstracts/variables";

.neeto-ui-container {
  padding: 0px !important;
}
